<template>
  <div :class="`card my-5 ${active ? 'active' : ''}`" @click="handleActiveTransfer(transfer?.id)">
    <div class="d-flex align-top justify-space-between mb-2">
      <div class="d-flex align-center">
        <h1 class="driving-number mb-0" v-if="transfer?.number">
          {{ transfer?.number }}
        </h1>
        <v-icon color="primary" class="ml-5" @click="openTransfer(transfer?.id)"> mdi-open-in-new </v-icon>
      </div>
      <div
        :class="
          'd-flex align-center status ' +
          getDrivingProposalStatusClass(transfer?.driving_proposal_status_id) +
          ' ' +
          transfer?.active_status
        ">
        <div class="circle me-2"></div>
        <p class="mb-0">
          {{ getDrivingProposalStatus(transfer?.driving_proposal_status_id) }}
        </p>
      </div>
    </div>
    <p class="date-time mb-2" v-if="transfer?.time || transfer?.date">
      {{ transfer?.time }}
      <span class="line" v-if="transfer?.time && transfer?.date">|</span>
      {{ transfer?.date }}
    </p>
    <p class="driver mb-0" v-if="(transfer?.profile && transfer?.profile?.full_name) || transfer?.profile?.phone">
      {{ transfer?.profile?.full_name }}
      <span class="line" v-if="transfer?.profile?.full_name && transfer?.profile?.phone">|</span>
      {{ transfer?.profile?.phone }}
    </p>
    <p class="vehicle mb-2" v-if="transfer?.vehicle?.brand || transfer?.vehicle?.licence_plate">
      {{ transfer?.vehicle?.brand }}
      <span class="plates" v-if="transfer?.vehicle?.licence_plate">
        {{ transfer?.vehicle?.licence_plate }}
      </span>
    </p>
    <p class="location mb-2 mt-2" v-if="locationName">
      <i class="mdi mdi-map-marker-outline"></i>
      {{ locationName }}
    </p>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          class="pt-4"
          v-model="vehicleSelected"
          :items="vehicles"
          clearable
          item-text="name"
          item-value="id"
          prepend-icon="mdi-format-list-group-plus"
          :label="$t('gpsMaps.vehicle')"
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()"
          @change="updateDriving()"></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          class="pt-4"
          v-model="driverSelected"
          :items="drivers"
          clearable
          item-text="profile.full_name"
          item-value="id"
          prepend-icon="mdi-format-list-group-plus"
          :label="$t('gpsMaps.driver')"
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()"
          @change="updateDriving()"></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import router from '@/router';

export default {
  name: 'TransferCard',
  props: ['transfer', 'drivers', 'vehicles', 'active'],
  components: {},
  data: () => ({
    statuses: {
      1: 'pending',
      2: 'accepted',
      3: 'rejected',
      4: 'expired',
      5: 'canceled',
      6: 'draft',
      7: 'done',
    },
    locationName: '',
    driverSelected: null,
    vehicleSelected: null,
  }),
  created() {
    this.getLocationName();

    this.driverSelected = this.transfer?.user_id;
    this.vehicleSelected = this.transfer?.vehicle?.vehicle_id;
  },
  computed: {},
  methods: {
    getDrivingProposalStatus(statusId) {
      return this.statuses[statusId] ? i18n.t(`drivingStatus.${this.statuses[statusId]}`) : '';
    },
    getDrivingProposalStatusClass(statusId) {
      return this.statuses[statusId] ? this.statuses[statusId] : '';
    },

    handleActiveTransfer(id) {
      this.$emit('handleActiveTransfer', id); // To know on which transfer we have clicked on
    },

    getLocationName() {
      this.$store
        .dispatch('googleMapsAPI/getLocationName', {
          lat: this.transfer?.latest_gps_coordinate?.lat,
          lng: this.transfer?.latest_gps_coordinate?.lng,
        })
        .then((data) => {
          this.locationName = data;
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.transfer?.id);
      formData.append('user_id', this?.driverSelected ? this?.driverSelected : '');
      formData.append('vehicle_id', this.vehicleSelected ? this.vehicleSelected : '');

      return formData;
    },
    async updateDriving() {
      let formData = this.createFormData();

      await this.$store
        .dispatch('gpsMapAPI/updateDrivingFromMap', formData)
        .then((res) => {
          if (res.success) {
            this.$emit('loadAllData');
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfully'),
              color: 'green',
            });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    openTransfer(transferId) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: this.$store.getters['auth/role'] == 1 ? 'TabularView' : 'CalendarView',
        query: { driving_id: transferId },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.card {
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;

  &:hover {
    // cursor: pointer;
  }

  &:hover,
  &.active {
    // background: var(--brand-variations-light-background-2, #edeefe);
  }
}

.driving-number {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.status {
  height: fit-content;
  background: var(--brand-variations-light-background-2, #edeefe);
  border-radius: 30px;
  padding: 8px 12px;

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
  }

  &.pending {
    .circle {
      background-color: #ecc600;
    }
  }

  &.accepted {
    .circle {
      background-color: #3cb503;
    }
  }

  &.rejected {
    .circle {
      background-color: #f46d6d;
    }
  }

  &.expired {
    .circle {
      background-color: #e1a750;
    }
  }

  &.canceled {
    .circle {
      background-color: #868686;
    }
  }

  &.draft {
    .circle {
      background-color: #7520ff;
    }
  }

  &.done {
    .circle {
      background-color: #868686;
    }
  }

  &.ongoing {
    .circle {
      animation: blink-animation 1s infinite; /* Apply the animation */
    }
  }

  p {
    color: var(--greys-Grey-1, #1b1d20);
    font-family: 'Inter', 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.date-time {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.driver,
.vehicle {
  color: var(--brand-variations-mid-dark2, #45484f);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;

  .plates {
    font-weight: 400;
  }
}

.location {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.line {
  color: var(--greys-Grey-7, #bcbcbc);
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
