<template>
  <div class="map-wrapper">
    <GmapMap
      v-if="center.lat && center.lng"
      :center="center"
      :zoom="9"
      map-type-id="roadmap"
      style="width: 100%; height: 100%">
      <GmapMarker
        :key="'transfer' + index"
        v-for="(item, index) in markers"
        :position="item?.latest_gps_coordinate"
        :clickable="true"
        :draggable="false"
        @click="handlePinClicked(item)"
        :icon="generateMapIcon(item)" />
    </GmapMap>

    <driver-card
      v-if="activeDriver?.id && selectedTab != 'drivers'"
      :key="activeDriver?.id"
      :driver="activeDriver"
      :active="false"
      :clickable="false">
    </driver-card>
  </div>
</template>

<script>
import DriverCard from './DriverCard.vue';

export default {
  name: 'GoogleMaps',
  props: ['selectedTab', 'drivers', 'transfers', 'activeTransferId', 'activeDriver'],
  components: { DriverCard },
  data: () => ({
    center: { lat: 1.1, lng: 1.1 }, // Where to center map
  }),
  created() {},
  computed: {
    // we show different location markers based on selected tab
    markers() {
      // we will return this later
      // switch (this.selectedTab) {
      // case 'transfers':
      // return this.transfers;
      // case 'drivers':
      // return this.drivers;
      // default:
      // return this.drivers;
      // }
      // we must filter drivers and show pins for drivers that have coordinates
      return this.drivers.filter((driver) => {
        return (
          driver?.latest_gps_coordinate && driver?.latest_gps_coordinate?.lat && driver?.latest_gps_coordinate?.lng
        );
      });
    },
  },
  mounted() {
    // center map on first marker if marker exist
    this.centerMap(this.markers[0]?.latest_gps_coordinate?.lat, this.markers[0]?.latest_gps_coordinate?.lng);
  },
  methods: {
    generateMapIcon(item) {
      // we generate pin icon with color based on transfer or driver status
      let color;

      // we will return this later
      // if (this.selectedTab == 'transfers') {
      // we generate pin icon with color based on transfer status
      // color = item?.id === this.activeTransferId ? '#0727DB' : '#444';
      // } else if (this.selectedTab == 'drivers') {
      // we generate pin icon with color based on driver status
      if (item?.id === this.activeDriver?.id) {
        color = '#0727DB';
      } else {
        switch (item?.status) {
          case 'offline':
            color = '#9CA0C8';
            break;
          case 'available':
            color = '#3CB503';
            break;
          case 'inTransfer':
            color = '#007D00';
            break;
          default:
            color = '#3CB503';
            break;
        }
      }
      // }
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="${color}" d="M12,6.5A3.5,3.5 0 0,1 15.5,10A3.5,3.5 0 0,1 12,13.5A3.5,3.5 0 0,1 8.5,10A3.5,3.5 0 0,1 12,6.5M12,2A8,8 0 0,0 4,10C4,16 12,22 12,22C12,22 20,16 20,10A8,8 0 0,0 12,2Z" /></svg>`;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    },

    handlePinClicked(item) {
      // we will return this later
      // if (this.selectedTab == 'transfers') {
      // this.$emit('handleActiveTransfer', item?.id); // To know on which pin we have clicked on
      // } else if (this.selectedTab == 'drivers') {
      // this.$emit('handleActiveDriver', item); // To know on which pin we have clicked on
      // }
      this.$emit('handleActiveDriver', item); // To know on which pin we have clicked on
    },

    centerMap(lat, lng) {
      if (lat && lng) {
        this.center = { lat: lat, lng: lng };
      } else {
        // center map on random coordinates if there is no markers
        this.centerMap(1.1, 1.1);
      }
    },
  },
  watch: {
    markers(val) {
      if (val.length && val[0]?.latest_gps_coordinate?.lat && val[0]?.latest_gps_coordinate?.lng) {
        // center map on first marker if marker exist
        this.centerMap(val[0]?.latest_gps_coordinate?.lat, val[0]?.latest_gps_coordinate?.lng);
      }
    },
    activeDriver(val) {
      if (val && val.latest_gps_coordinate?.lat && val.latest_gps_coordinate?.lng) {
        this.centerMap(val?.latest_gps_coordinate?.lat, val?.latest_gps_coordinate?.lng);
      } // when we click on pin we center map in way that the pin is in center of it
    },
  },
};
</script>

<style>
.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
